import { isNgContainer } from "@angular/compiler";

export const Navigation = [
    {
        label: 'Projects',
        icon: 'pi pi-fw pi-home',
        items: [
            { label: 'Projects', icon: 'pi pi-fw pi-th-large', routerLink: ['/dashboards/project'] },
            // { label: 'Applications', icon: 'pi pi-fw pi-th-large', routerLink: ['/dashboards/insights'] }
        ]
    },
    {
        label: 'Assets',
        icon:'pi pi-fw pi-file',
        items: [
            { label: 'Assets', icon: 'pi pi-fw pi-file',
            items: [
                { label: 'Asset View', icon: 'pi pi-fw pi-list', routerLink: ['/assets/assetView'] },
                { label: 'Volumes', icon: 'pi pi-fw pi-inbox', routerLink: ['/assets/volumes'] },
                { label: 'Networks', icon: 'pi pi-fw pi-sitemap', routerLink: ['/assets/networks']},
                { label: 'Load Balancers', icon: 'pi  pi-arrows-h', routerLink: ['/assets/loadBalancers'] },
                { label: 'Application Gateway', icon: 'pi pi-fw pi-desktop', routerLink: ['/assets/appGateway'] },
                { label: 'Lambda Functions', icon: 'pi pi-fw pi-cog', routerLink: ['/assets/lambdaFunctions'] },
                { label: 'Storage Buckets', icon: 'pi pi-fw pi-calendar', routerLink: ['/assets/storageBuckets'] },
              ]
             },
            // { label: 'Applications', icon: 'pi pi-fw pi-th-large', routerLink: ['/dashboards/insights'] }
        ]
    },
    // {
    //     label: 'Dashboards',
    //     icon: 'pi pi-fw pi-home',
    //     items: [
    //         { label: 'Cost Analysis', icon: 'pi pi-fw pi-chart-line', routerLink: ['/dashboards/project'] },
    //         { label: 'Cost Insights', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/dashboards/insights'] }
    //     ]
    // },
    // {
    //     label: 'Databases',
    //     items: [
    //         { label: 'Database list', icon: 'pi pi-fw pi-database', routerLink: ['/database/list'] }
    //     ]
    // },
    // {
    //     label: 'Rule setup',
    //     icon: 'pi pi-fw pi-check-square',
    //     items: [
    //         { label: 'Data validation', icon: 'pi pi-fw pi-home', routerLink: ['/rule/data-validation'] },
    //         { label: 'Data Analytics', icon: 'pi pi-fw pi-home', routerLink: ['/rule/data-analytics'] },
    //     ]
    // },
    // {
    //     label: 'Data Object',
    //     icon: 'pi pi-fw pi-home',
    //     items: [
    //         { label: 'Database Connection', icon: 'pi pi-fw pi-sitemap', routerLink: ['/data/list'] }
    //     ]
    // },
    // {
    //     label: 'Products',
    //     icon: 'pi pi-fw pi-clone',
    //     items: [
    //         { label: 'Templates', icon: 'pi pi-fw pi-th-large', routerLink: ['/products/templates'] },
    //         { label: 'Use cases', icon: 'pi pi-fw pi-home', routerLink: ['/data/usecase'] },
    //     ]
    // },
    {
        label: 'Configurations',
        icon: 'pi pi-fw pi-wrench',
        items: [
            { label: 'Data flow', icon: 'pi pi-fw pi-sitemap', routerLink: ['/configurations/data-builder'] },
            { label: 'Connection Vault', icon: 'pi pi-fw pi-database', routerLink: ['/configurations/connection-vault'] },
            { label: 'Master data management', icon: 'pi pi-fw pi-user', routerLink: ['/configurations/mdm'] },
            { label: 'Validation configuration', icon: 'pi pi-fw pi-check-square', routerLink: ['/configurations/validation'] },
            // { label: 'Product setup', icon: 'pi pi-fw pi-home', routerLink: ['/configurations/settings'] },
            { label: 'Users', icon: 'pi pi-fw pi-user', routerLink: ['/settings/users'] },
        ]
    },
    {
        label: 'Settings',
        icon: 'pi pi-fw pi-cog',
        items: [
            { label: 'Notification & Alerts', icon: 'pi pi-fw pi-bell', routerLink: ['/settings/notification'] },
            { label: 'Account', icon: 'pi pi-fw pi-check-square', routerLink: ['/settings/account'] },
            { label: 'Users', icon: 'pi pi-fw pi-user', routerLink: ['/settings/users'] },
            { label: 'Roles', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/settings/roles'] },
            { label: 'Jobs', icon: 'pi pi-fw pi-briefcase', routerLink: ['/settings/jobs'] },
            { label: 'Security', icon: 'pi pi-fw pi-exclamation-circle', title: 'Manage your password and 2-step verification preferences', routerLink: ['/settings/security'] },
            {label:'cloud-profile', icon:'pi pi-fw pi-cloud', routerLink:['/settings/cloud-profile']}
        ]
    },
    {
        label: 'Logs',
        title: 'Tracking and logging user activities',
        icon: 'pi pi-fw pi-globe',
        items: [
            { label: 'Login Audit', icon: 'pi pi-fw pi-eye', routerLink: ['/log/login-audit'], badge: '' },
            { label: 'System Audit', icon: 'pi pi-fw pi-globe', routerLink: ['/log/system-audit'] },
        ]
    }
];

// recon 
// validation 
// analytical
// operation
