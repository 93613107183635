import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';

@Component({
  selector: 'dynamic-breadcrumb',
  templateUrl: './dynamic-breadcrumb.component.html',
  styleUrls: ['./dynamic-breadcrumb.component.scss']
})
export class DynamicBreadcrumbComponent implements OnInit {
  // items: MenuItem[] | undefined;

  // home: MenuItem | undefined;

  constructor(public _breadcrumbService: BreadcrumbService) {}

  ngOnInit() {
      // this.items = [{ label: 'Computer', routerLink: '/' }, { label: 'Notebook' }, { label: 'Accessories' }, { label: 'Backpacks' }, { label: 'Item' }];

      // this.home = { icon: 'pi pi-home', routerLink: '/' };
  }
}
