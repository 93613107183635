<p-card>
  <p-toast></p-toast>
  <div styleClass="mb-4 gap-2 bg-white">
    <div class="grid" *ngIf="tableOption">
      <div class="col-6">
        <ng-container *ngIf="tableOption.addButton">
          <ng-container *ngFor="let btn of tableOption.buttonProperties">
            <p-button pRipple size="small" [label]="btn.label" [icon]="btn.iconClass" [rounded]="true" [text]="true" [severity]="btn.severity" class="mr-2" (click)="addEventHandler(btn.label)"></p-button>
          </ng-container>
        </ng-container>
      </div>
      <div class="col-6 flex justify-content-end column-gap-2">
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" name="search" (input)="searchInput($event)" placeholder="Search keyword" />
        </span>
        <p-button *ngIf="tableOption.refreshData" pRipple size="small" [rounded]="true" [text]="true" [severity]="'secondary'" icon="pi pi-refresh" class="p-button-info" (click)="refreshTable()"></p-button>
        <p-button *ngIf="tableOption.isDownload" pRipple size="small" [rounded]="true" [text]="true" [severity]="'warning'" icon="pi pi-download" class="p-button-info" (click)="downloadMenu.toggle($event)"></p-button>
        <p-menu #downloadMenu [model]="downloadItems" [popup]="true" appendTo="body"></p-menu>
        
        <p-button *ngIf="tableOption.refreshData" pRipple size="small" [rounded]="true" [text]="true" [severity]="'help'" icon="pi pi-table" class="p-button-info" (click)="columnHideMenu.toggle($event)"></p-button>
        <p-overlayPanel #columnHideMenu>
          <p-listbox 
            (click)="$event.stopPropagation()"
            [filter]="true" 
            [options]="columns" 
            [(ngModel)]="selectedColumns" 
            optionLabel="header" 
            [multiple]="true" 
            [metaKeySelection]="false"
            [checkbox]="true"
            [selectAll]="selectAll"
            [style]="{ width: '15rem' }" 
            [listStyle]="{'max-height': '220px'}"
            (onSelectAllChange)="onSelectAllChange($event)"
            (onChange)="onChange($event)">
          </p-listbox>
        </p-overlayPanel>
      </div>
    </div>
  </div>
  <p-progressBar *ngIf="isProgressBarVisible" mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
  <!-- [styleClass]="'p-datatable-gridlines'" 
    (sortFunction)="customSort($event)" 
    [customSort]="false" -->
  <p-table
    #dt
    [tableStyle]="{ 'min-width': '50rem' }"
    class="white-space-nowrap"
    [styleClass]="tableOption.styleClass != '' ? tableOption.styleClass : 'white-space-nowrap'"
    [value]="tableData"
    [resizableColumns]="resizableColumns"
    [reorderableColumns]="reorderableColumns"
    [selection]="selectedItems"
    [globalFilterFields]="globalFilterFields"
    [scrollable]="scrollable"
    [columns]="selectedColumns"
    [lazy]="false"
    (onLazyLoad)="lazyLoadSort($event)"
    [paginator]="paginator"
    [loading]="loading"
    [first]="first"
    [rows]="tableOption.pageSize"
    [rowsPerPageOptions]="tableOption.pageSizeOptions"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    (onPage)="pageChange($event)"
    >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngIf="tableOption.rowSelection" style="width: 4rem" alignFrozen="left" pFrozenColumn [frozen]="true">
          <p-tableHeaderCheckbox [name]="columns"></p-tableHeaderCheckbox>
        </th>
        <ng-container *ngFor="let col of columns">
          <th *ngIf="col.isVisible"
            pReorderableColumn 
            pResizableColumn 
            pFrozenColumn 
            [frozen]="col.frozen ? true : false"
            [pSortableColumn]="col.isSort ? col.field : ''"
            >
              {{col.header}}
              <ng-container *ngIf="col.isSort">
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </ng-container>
          </th>
        </ng-container>
        <th *ngIf="tableOption.showAction" alignFrozen="right" pFrozenColumn [frozen]="true">Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngIf="tableOption.rowSelection" alignFrozen="left" pFrozenColumn [frozen]="true">
          <p-tableCheckbox [value]="rowData" [name]="rowData"></p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns" pFrozenColumn [frozen]="col.frozen ? true : false">
          <ng-container *ngIf="!col.isHyperLink && col.field !== 'is_active' && !col.dateFormat">
            <span class="text-sm">{{ rowData[col.field]}}</span>
          </ng-container>
          <ng-container *ngIf="col.dateFormat">
            <!-- <span class="text-sm date">{{ rowData[col.field] | formatCell: col.dateFormat}}</span> -->
            <span class="text-sm date" innerHTML="{{ rowData[col.field] | formatCell:col.dateFormat}}"></span>
          </ng-container>
          <p-tag *ngIf="col.field === 'is_active'" [severity]="rowData[col.field] ? 'success' : 'danger'" [icon]="rowData[col.field] ? 'pi pi-check' : 'pi pi-times'"></p-tag>
          <span *ngIf="col.isHyperLink && col.field !== 'is_active'">
            <p-button [link]="true" [styleClass]="'p-0'" [label]="rowData[col.field]" [text]="true" (click)="onClickHyperLink(rowData, col)"></p-button>
          </span>
        </td>
        <td *ngIf="tableOption.showAction" alignFrozen="right" pFrozenColumn [frozen]="true">
          <p-button icon="pi pi-ellipsis-v" (click)="actionMenu.toggle($event)" [rounded]="true" [text]="true"></p-button>
          <p-menu #actionMenu [model]="tableOption.actionMenuItems" [popup]="true" appendTo="body">
            <ng-template pTemplate="item" let-item>
              <a class="p-menuitem-link" (click)="menuEventCallback(item, rowData)">
                <span [class]="item.icon"></span>
                <span class="ml-2">{{ item.label }}</span>
              </a>
            </ng-template>
          </p-menu>
        </td>
      </tr>
    </ng-template>

    <!-- <ng-template pTemplate="summary">
      <div class="flex align-items-center justify-content-end">
        <span class="mx-1 text-color">Items per page: </span>
        <p-dropdown [options]="tableOption.pageSizeOptions" optionLabel="label" optionValue="value" [(ngModel)]="tableOption.pageSize" (ngModelChange)="first2 = 0"></p-dropdown>
        <p-paginator [first]="first2" [rows]="tableOption.pageSize ? tableOption.pageSize : 10" [totalRecords]="tableData.length" (onPageChange)="onPageChange2($event)" [showCurrentPageReport]="true"
            currentPageReportTemplate="{first} - {last} of {totalRecords}" [showPageLinks]="false" [showFirstLastIcon]="false" ></p-paginator>
      </div>
    </ng-template> -->

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="getColumnsLength()">No data found.</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>

<p-toast></p-toast>
  
