import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'compact-layout',
  templateUrl: './compact.component.html',
  styleUrls: ['./compact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompactLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
