import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyLayoutComponent } from './empty.component';
import { RouterModule } from '@angular/router';
import {StyleClassModule} from 'primeng/styleclass';



@NgModule({
  imports: [
    CommonModule,

    RouterModule,
    StyleClassModule
  ],
  declarations: [EmptyLayoutComponent],
  exports: [EmptyLayoutComponent]
})
export class EmptyLayoutModule { }
