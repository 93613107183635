import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DateAgoPipe, FilterPipe, FormatCellPipe, UtcToLocale } from './app.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [FilterPipe, FormatCellPipe, UtcToLocale, DateAgoPipe],
  exports: [FilterPipe, FormatCellPipe, UtcToLocale, DateAgoPipe],
  providers: [DatePipe]
})
export class PipeModule { }
