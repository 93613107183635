import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { LayoutModule } from './layout/layout.module';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { appRoutes } from './app.routing';
import { CoreModule } from './core/core.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomerIdInterceptor } from './core/auth/customerId.interceptor';
// import { FlexmonsterPivotModule } from 'ng-flexmonster';


const routerConfig: ExtraOptions = {
  preloadingStrategy       : PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxEchartsDirective,
    HttpClientModule,
    // FlexmonsterPivotModule,
    CoreModule,
    LayoutModule,
    RouterModule.forRoot(appRoutes, routerConfig)
  ],
  providers: [
    provideEcharts(),
    { provide: HTTP_INTERCEPTORS, useClass: CustomerIdInterceptor, multi: true } // Provide the interceptor
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
