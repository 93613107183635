<div>
  <p-messages [(value)]="message" [enableService]="false" [closable]="false"></p-messages>
  <form [formGroup]="form" novalidate="off">
    <div *ngFor="let formControl of listOfFormElements" class="form-row">
      <app-dynamic-control 
        [outputValue]="Valuesoutput" 
        [formElement]="formControl" 
        [form]="form"
        (valChange)="fieldValChange($event)"
        (fileUpload)="fileEventChange($event)"
        (focusHandle)="focusEventHandle($event)">
      </app-dynamic-control>
    </div>
    
    <div class="flex form-row column-gap-3 mt-4 justify-content-center">
      <button type="submit" 
        *ngIf="isSaveBtn"
        class="p-button-success w-auto"
        severity="success"
        (click)="Save(form)" 
        pButton 
        pRipple>
        {{fnBtnName}}
      </button> <!-- [disabled]="!form || !form.valid"  -->
      <button type="submit" 
        (click)="Cancel()" 
        severity="secondary"
        class="p-button-secondary w-auto"
        *ngIf="isCloseBtn" pButton pRipple>{{closeBtnName}}</button>
    </div>
  </form>
</div>
