import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { EnterpriseLayoutModule } from './layouts/horizontal/enterprise/enterprise.module';
import { ModernLayoutModule } from './layouts/horizontal/modern/modern.module';
import { ClassyLayoutModule } from './layouts/vertical/classy/classy.module';
import { CompactLayoutModule } from './layouts/vertical/compact/compact.module';
import { EmptyLayoutModule } from './layouts/empty/empty.module';



const layoutModules = [
  // Empty
  EmptyLayoutModule,

  // Horizontal navigation
  // CenteredLayoutModule,
  EnterpriseLayoutModule,
  // MaterialLayoutModule,
  ModernLayoutModule,

  // Vertical navigation
  // ClassicLayoutModule,
  ClassyLayoutModule,
  CompactLayoutModule,
  // DenseLayoutModule,
  // FuturisticLayoutModule,
  // ThinLayoutModule
];


@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    ...layoutModules
  ],
  exports: [
    LayoutComponent,
    ...layoutModules
  ]
})
export class LayoutModule { }
