<div [formGroup]="form">
  <div [ngSwitch]="formElement.type">
    <!-- TextBox -->
    <ng-container *ngIf="formElement && formElement.visible && !formElement.depends_on || formElement?.depends_on.length === 0"> <!--  *ngIf="formElement && !formElement.depends_on || formElement?.depends_on.length === 0"  -->
      <div class="flex flex-row mb-3 gap-3" *ngSwitchCase="'label'">  
        <label class="w-3 flex align-items-center" [for]="formElement.name">{{ formElement.label }}
          <span *ngIf="formElement.validators?.required" class="text-red-500 ml-2">*</span>
          <span *ngIf="formElement && formElement.description" class="text-primary-500 ml-1" [pTooltip]="formElement.description">ⓘ</span>
        </label>
      </div>
    </ng-container>
    <ng-container *ngIf="formElement && formElement.visible && !formElement.depends_on || formElement?.depends_on.length === 0">
      <div class="flex flex-row mb-3 gap-3" *ngIf="['text', 'password', 'email', 'number', 'search', 'tel', 'url'].includes(formElement.type)">  
        <label class="w-3 flex align-items-center" [for]="formElement.name">{{ formElement.label }}
          <span *ngIf="formElement.validators?.required" class="text-red-500 ml-2">*</span>
          <span *ngIf="formElement && formElement.description" class="text-primary-500 ml-1" [pTooltip]="formElement.description">ⓘ</span>
        </label>
  
        <div class="w-5">
          <input class="" pInputText 
                [placeholder]="formElement.placeholder"
                [type]="formElement.type" 
                [required]="formElement.validators?.required"
                [formControlName]="formElement.key"
                autocomplete="off">
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="formElement && formElement.visible && formElement.depends_on && formElement.depends_on.length > 0 && !formElement.depends_value">
      <div class="flex flex-row mb-3 gap-3" *ngIf="['text', 'password', 'email', 'number', 'search', 'tel', 'url'].includes(formElement.type)">  
        <label class="w-3 flex align-items-center" [for]="formElement.name">{{ formElement.label }}
          <span *ngIf="formElement.validators?.required" class="text-red-500 ml-2">*</span>
          <span *ngIf="formElement && formElement.description" class="text-primary-500 ml-1" [pTooltip]="formElement.description">ⓘ</span>
        </label>
  
        <div class="w-5">
          <input class="" pInputText 
                [placeholder]="formElement.placeholder"
                [type]="formElement.type" 
                [required]="formElement.validators?.required"
                [formControlName]="formElement.key"
                autocomplete="off">
          <!-- <small class="mt-2" *ngIf="hasError(formElement.key, 'required')">
            {{formElement.name}} is required. <span class="ml10">{{formElement.errmsg}}</span>
          </small>
          <small class="mt-2" *ngIf="hasError(formElement.key, 'email')">
            Invalid {{formElement.name}}. Please enter a valid email address.
          </small> -->
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="formElement.depends_on && formElement?.depends_on.length > 0 && formElement.depends_value && formElement.depends_value.includes(form.get(formElement && formElement.depends_on && formElement.depends_on[0])?.value)">
      <div class="flex flex-row mb-3 gap-3" *ngIf="['text', 'password', 'email', 'number', 'search', 'tel', 'url'].includes(formElement.type)">  
        <label class="w-3 flex align-items-center" [for]="formElement.name">{{ formElement.label }}
          <span *ngIf="formElement.validators?.required" class="text-red-500 ml-2">*</span>
          <span *ngIf="formElement && formElement.description" class="text-primary-500 ml-1" [pTooltip]="formElement.description">ⓘ</span>
        </label>
        <div class="w-5">
          <input class="" pInputText 
                [placeholder]="formElement.placeholder"
                [type]="formElement.type" 
                [required]="formElement.validators?.required"
                [formControlName]="formElement.key"
                autocomplete="off">
          <!-- <small class="mt-2" *ngIf="hasError(formElement.key, 'required')">
            {{formElement.name}} is required. <span class="ml10">{{formElement.errmsg}}</span>
          </small>
          <small class="mt-2" *ngIf="hasError(formElement.key, 'email')">
            Invalid {{formElement.name}}. Please enter a valid email address.
          </small> -->
        </div>
      </div>
    </ng-container>
      <!-- (keydown)="onKeydownMain($event)" -->
    <!-- TextBox -->

    <!-- Upload -->
    <div class="flex flex-row mb-3 gap-3" *ngSwitchCase="'file'">
      <label class="w-3 flex align-items-center" [for]="formElement.name">
        {{formElement.label}} 
        <span *ngIf="formElement.validators?.required" class="text-red-500 ml-2">*</span>
        <span class="text-primary-500 ml-1" [pTooltip]="formElement.description">ⓘ</span>
      </label>
      <div class="w-5">
        <p-fileUpload 
          name="file"
          [url]="uploadUrl"
          (onSelect)="onFileUploadSuccess($event)"
          (uploadHandler)="uploadFile()"
          [accept]="acceptedFormats"
          [maxFileSize]="maxFileSize"
          [auto]="false"
          [previewWidth]="0"
          [customUpload]="true"
          [showUploadButton]="true"
          [showCancelButton]="true"
          chooseLabel="Choose File"
          styleClass="p-button-secondary p-button-rounded"
        ></p-fileUpload>
      </div>
    </div>
    <!-- Upload -->

    <!-- DropDown -->
      <ng-container *ngIf="formElement  && formElement.visible && !formElement.depends_on || formElement?.depends_on.length === 0"> <!--  *ngIf="formElement && !formElement.depends_on || formElement?.depends_on.length === 0" -->
        <div class="flex flex-row mb-3 gap-3" *ngSwitchCase="'select'">
          <label class="w-3 flex align-items-center" [for]="formElement.name">
            {{formElement.label}}
            <span *ngIf="formElement.validators?.required" class="text-red-500 ml-2">*</span>
            <span class="text-primary-500 ml-1" *ngIf="formElement && formElement.description" [pTooltip]="formElement.description">ⓘ</span>
          </label>
          <!-- [dropdownIcon]="formElement.options.length === 0 ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" -->
          <div class="w-5">
            <p-dropdown
              class="w-100"
              [filter]="true"
              #dropdown
              [id]="formElement.name"
              [formControlName]="formElement.key"
              [required]="formElement.validators?.required"
              [options]="formElement.options" 
              [optionLabel]="getOptionLabel(formElement)"
              [optionValue]="getOptionValue(formElement)"
              [placeholder]="formElement.placeholder"
              [virtualScroll]="true"
              [virtualScrollItemSize]="10"
              (onChange)="callBack($event, formElement.callback, formElement.key)"
              (onFocus)="eventFocusHandle(formElement)"
              [appendTo]="dropdown">
            </p-dropdown>
          </div>
        </div>
      </ng-container>
      <!--  enableDisableControl(formElement, controlName); -->
      <ng-container *ngIf="formElement  && formElement.visible && formElement.depends_on && formElement?.depends_on.length > 0 && !formElement.depends_value"> <!-- formElement.depends_on[0])?.value === formElement?.depends_value && isDependenciesMet(formElement) formElement.depends_on.includes(this.form.get(formElement.depends_key)?.value !== '') || (formElement && formElement.depends_on && formElement.depends_on.length > 0 && formElement.depends_on.includes(formElement.key))" formElement?.depends_on.length > 0 && -->
        <div class="flex flex-row mb-3 gap-3" *ngSwitchCase="'select'">
          <label class="w-3 flex align-items-center" [for]="formElement.name">
            {{formElement.label}}
            <span *ngIf="formElement.validators?.required" class="text-red-500 ml-2">*</span>
            <span *ngIf="formElement && formElement.description" class="text-primary-500 ml-1" [pTooltip]="formElement.description">ⓘ</span>
          </label>
          <div class="w-5">
            <p-dropdown
              class="w-100"
              [filter]="true"
              #dropdown
              [id]="formElement.name"
              [formControlName]="formElement.key"
              [required]="formElement.validators?.required"
              [options]="formElement.options" 
              [optionLabel]="getOptionLabel(formElement)"
              [optionValue]="getOptionValue(formElement)"
              [placeholder]="formElement.placeholder"
              [virtualScroll]="true"
              [virtualScrollItemSize]="20"
              (onChange)="callBack($event, formElement.callback, formElement.key)"
              (onFocus)="eventFocusHandle(formElement)"
              [appendTo]="dropdown">
            </p-dropdown>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="formElement.depends_on && formElement?.depends_on.length > 0 && formElement.depends_value && formElement.depends_value.includes(form.get(formElement && formElement.depends_on && formElement.depends_on[0])?.value)">
        <div class="flex flex-row mb-3 gap-3" *ngSwitchCase="'select'">
          <label class="w-3 flex align-items-center" [for]="formElement.name">
            {{formElement.label}}
            <span *ngIf="formElement.validators?.required" class="text-red-500 ml-2">*</span>
            <span *ngIf="formElement && formElement.description" class="text-primary-500 ml-1" [pTooltip]="formElement.description">ⓘ</span>
          </label>
          <div class="w-5">
            <p-dropdown
              class="w-100"
              [filter]="true"
              #dropdown
              [id]="formElement.name"
              [formControlName]="formElement.key"
              [required]="formElement.validators?.required"
              [options]="formElement.options" 
              [optionLabel]="getOptionLabel(formElement)"
              [optionValue]="getOptionValue(formElement)"
              [placeholder]="formElement.placeholder"
              [virtualScroll]="true"
              [virtualScrollItemSize]="20"
              (onChange)="callBack($event, formElement.callback, formElement.key)"
              (onFocus)="eventFocusHandle(formElement)"
              [appendTo]="dropdown">
            </p-dropdown>
          </div>
        </div>
      </ng-container>
    <!-- DropDown -->

    <!-- CheckBox -->
    <div class="flex flex-row mb-3 gap-3" *ngSwitchCase="'checkbox'">
      <div class="w-3 flex align-items-center">
        <label [for]="formElement.name+98">
          {{formElement.label}} 
        </label>
        <span *ngIf="formElement.validators.required" class="text-red-500 ml-2">*</span>
        <span class="text-primary-500 ml-1" [pTooltip]="formElement.description">ⓘ</span>
      </div>
      <div class="w-5">
        <p-checkbox 
          [inputId]="formElement.name+98"
          [formControlName]="formElement.key"
          [required]="formElement.validators?.required"
          [ariaChecked]="formElement.default"
          [value]="formElement.default">
        </p-checkbox>
      </div>
    </div>
    <!-- CheckBox -->

    <!-- multi select -->
    <ng-container *ngIf="formElement && formElement.visible && formElement.depends_on && formElement?.depends_on.length && isDependenciesMet(formElement)"> <!--  *ngIf="formElement && !formElement.depends_on || formElement?.depends_on.length === 0" -->
      <div class="flex flex-row mb-3 gap-3" *ngSwitchCase="'multi-select'">
        <label class="w-3 flex align-items-center" [for]="formElement.name">
          {{formElement.label}}
          <span *ngIf="formElement.validators?.required" class="text-red-500 ml-2">*</span>
          <span class="text-primary-500 ml-1" *ngIf="formElement && formElement.description" [pTooltip]="formElement.description">ⓘ</span>
        </label>
        <!-- [dropdownIcon]="formElement.options.length === 0 ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" -->
        <div class="w-5">
          <p-multiSelect 
            #multidropdown
            class="w-100"
            [filter]="true"
            [showClear]="true"
            [id]="formElement.name"
            [formControlName]="formElement.key"
            [options]="formElement.options" 
            [optionLabel]="getOptionLabel(formElement)"
            [optionValue]="getOptionValue(formElement)"
            [placeholder]="formElement.placeholder"
            (onChange)="callBack($event, formElement.callback, formElement.key)"
            (onFocus)="eventFocusHandle(formElement)"
            [appendTo]="multidropdown"
            >
          </p-multiSelect>
        </div>
      </div>
    </ng-container>
    
    <!-- SlideToggle -->
    <!-- <div class="flex flex-row mb-3" *ngSwitchCase="'slidetoggle'">
      <div class="w-3 flex align-items-center">
        <label [for]="formElement.name+186" class="">
          {{formElement.label}} 
        </label>
        <span *ngIf="formElement.validators.required" class="text-red-500 ml-2">*</span>
        <span class="text-primary-500 ml-1" [pTooltip]="formElement.description">ⓘ</span>
      </div>
      <div class="w-7">
        <p-inputSwitch 
          [inputId]="formElement.name+186"
          [formControlName]="formElement.key" 
          [required]="formElement.validators?.required">
        </p-inputSwitch>
      </div>
    </div> -->
    <!-- SlideToggle -->

    <!-- RadioButton -->
    <!-- <div class="form-element" *ngSwitchCase="'radiobutton'">
      <div *ngFor="let items of formElement.options" class="field-checkbox">
        <label [for]="items.name" class="ml-2">{{items.key}}</label>
        <p-radioButton 
          [inputId]="items.name" 
          [name]="items.key" 
          [id]="items.key"
          [value]="items.value" 
          [required]="formElement.validators?.required"
          [formControlName]="formElement.key">
        </p-radioButton>
      </div>
    </div> -->
    <!-- RadioButton -->

    <!-- TextArea -->
    <!-- <mat-form-field appearance="outline" class="form-element" *ngSwitchCase="'textarea'">
      <mat-label>{{formElement.label}}</mat-label>
      <textarea matInput [(ngModel)]="outputValue[formElement.key]" [placeholder]="formElement.label"
               [formControlName]="formElement.key" [id]="formElement.key"
                [value]="formElement.value"></textarea>
      <mat-error>
        {{formElement.label}} is required
      </mat-error>
    </mat-form-field> -->
    <!-- TextArea -->

    <!-- DatePicker -->
    <!-- <mat-form-field appearance="outline" class="form-element" *ngSwitchCase="'datepicker'">
      <mat-label>{{formElement.label}}</mat-label>
      <input matInput [(ngModel)]="outputValue[formElement.key]" [formControlName]="formElement.key"
             [matDatepicker]="picker" [placeholder]="formElement.label">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error> {{formElement.label}} is required </mat-error>
    </mat-form-field> -->
    <ng-container *ngIf="formElement  && formElement.visible && !formElement.depends_on || formElement?.depends_on.length === 0">
      <div class="flex flex-row mb-3 gap-3" *ngSwitchCase="'date'">
        <label class="w-3 flex align-items-center" [for]="formElement.name">
          {{formElement.label}} 
          <span *ngIf="formElement.validators.required" class="text-red-500 ml-2">*</span>
          <span class="text-primary-500 ml-1" [pTooltip]="formElement.description">ⓘ</span>
        </label>
        <div class="w-5">
          <p-calendar #calendar [formControlName]="formElement.key" dateFormat="yyyy/mm/dd" [appendTo]="calendar" iconDisplay="input" [showIcon]="true" inputId="templatedisplay"></p-calendar>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="formElement  && formElement.visible && formElement.depends_on && formElement?.depends_on.length > 0 && !formElement.depends_value">
      <div class="flex flex-row mb-3 gap-3" *ngSwitchCase="'date'">
        <label class="w-3 flex align-items-center" [for]="formElement.name">
          {{formElement.label}} 
          <span *ngIf="formElement.validators.required" class="text-red-500 ml-2">*</span>
          <span class="text-primary-500 ml-1" [pTooltip]="formElement.description">ⓘ</span>
        </label>
        <div class="w-5">
          <p-calendar #calendar [formControlName]="formElement.key" dateFormat="yy/mm/dd" [appendTo]="calendar" iconDisplay="input" [showIcon]="true" inputId="templatedisplay"></p-calendar>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="formElement.depends_on && formElement?.depends_on.length > 0 && formElement.depends_value && formElement.depends_value.includes(form.get(formElement && formElement.depends_on && formElement.depends_on[0])?.value)">
      <div class="flex flex-row mb-3 gap-3" *ngSwitchCase="'date'">
        <label class="w-3 flex align-items-center" [for]="formElement.name">
          {{formElement.label}} 
          <span *ngIf="formElement.validators.required" class="text-red-500 ml-2">*</span>
          <span class="text-primary-500 ml-1" [pTooltip]="formElement.description">ⓘ</span>
        </label>
        <div class="w-5">
          <p-calendar #calendar [formControlName]="formElement.key" dateFormat="yy-mm-dd" [showTime]="true" [appendTo]="calendar" iconDisplay="input" [showIcon]="true" inputId="templatedisplay"></p-calendar>
        </div>
      </div>
    </ng-container>
    <!-- DatePicker -->

  </div>
</div>
