import { LayoutService } from './../../../../../@primeng/services/layout.service';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'classy-layout',
  templateUrl: './classy.component.html',
  styleUrls: ['./classy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit {

  items!: MenuItem[];
  userItems!: MenuItem[];

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  mini: boolean = true;

  anchored: boolean = false;



  constructor(public layoutService: LayoutService, private cd: ChangeDetectorRef, private _authService: AuthService) { }

  ngOnInit() {
    this.userItems = [
      {
          label: 'Profile',
          icon: PrimeIcons.USER,
      },
      {
          label: 'Logout',
          icon: PrimeIcons.SIGN_OUT,
          command: () => {
            this._authService.signOut();
          }
      }
    ];
  }

  get containerClass() {
    return {
      'layout-theme-light': this.layoutService.config.colorScheme === 'light',
      'layout-theme-dark': this.layoutService.config.colorScheme === 'dark',
      'layout-overlay': this.layoutService.config.menuMode === 'overlay',
      'layout-static': this.layoutService.config.menuMode === 'static',
      'layout-slim': this.layoutService.config.menuMode === 'slim',
      'layout-slim-plus': this.layoutService.config.menuMode === 'slim-plus',
      'layout-reveal': this.layoutService.config.menuMode === 'reveal',
      'layout-drawer': this.layoutService.config.menuMode === 'drawer', 
      'layout-horizontal': this.layoutService.config.menuMode === 'horizontal',
      'layout-static-inactive': this.layoutService.state.staticMenuDesktopInactive && this.layoutService.config.menuMode === 'static',
      'layout-overlay-active': this.layoutService.state.overlayMenuActive,
      'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
      'p-input-filled': this.layoutService.config.inputStyle === 'filled',
      'p-ripple-disabled': !this.layoutService.config.ripple
    }
  }

  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
}

  toggleSidebar() {
    if(this.layoutService.config.menuMode === 'reveal' || this.layoutService.config.menuMode === 'drawer') {
      if (this.mini) {
        console.log("opening sidebar");
        let elements = document.getElementsByClassName('layout-wrapper');

        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('layout-sidebar-active'); // Add your class name here
        }
        // this.layoutService.config.menuMode = 'reveal';
        this.mini = false;
      } else if(!this.mini && !this.anchored) {
        console.log("closing sidebar");
        let elements = document.getElementsByClassName('layout-wrapper');

        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('layout-sidebar-active'); // Add your class name here
        }
        this.mini = true;
        this.cd.detectChanges();
      }
    }
  }

  anchorSidebar() {
    if(this.layoutService.config.menuMode === 'reveal' || this.layoutService.config.menuMode === 'drawer') {
      if (!this.anchored) {
        console.log("opening sidebar");
        let elements = document.getElementsByClassName('layout-wrapper');

        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('layout-sidebar-anchored'); // Add your class name here
        }
        // this.layoutService.config.menuMode = 'reveal';
        this.anchored = true;
      } else if(this.anchored) {
        console.log("closing sidebar");
        let elements = document.getElementsByClassName('layout-wrapper');

        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('layout-sidebar-anchored'); // Add your class name here
        }
        this.anchored = false;
        this.cd.detectChanges();
      }
    }
  }

}
