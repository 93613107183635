import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  items: MenuItem[] = [];

  // Method to update breadcrumb items dynamically
  setItems(items: MenuItem[]): void {
    this.items = items;
  }

}
