import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  displayDialog = new EventEmitter();
  public saveNodeValue = new EventEmitter();

  private storeData = new BehaviorSubject('');
  private nodeData = new BehaviorSubject('');
  public getData$ = this.storeData.asObservable();
  public getNodeData$ = this.nodeData.asObservable();

  private setConnectedNode$ = new ReplaySubject<any>();

  //! GET / SET CREATED NODE ID
  private setCreatedNode: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public getCreatedNodeId$: Observable<any> = this.setCreatedNode.asObservable();
  public isEnableSave: boolean | undefined;
  public setExecutedFlowDataSubject = new Subject<any>();
  currentExecutedRecords: any = {};
  // Initialize BehaviorSubject with an empty object or any default value
  // private createdNodeSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  // public createdNode$: Observable<any> = this.createdNodeSubject.asObservable();


  constructor() { }

  setConnectedDataTarget(input: number, output: number, all: any, connection?: boolean) {
    let params: any = {};
    params = { input: input, output: output, all: all, connection: connection };
    this.setConnectedNode$.next(params);
  }

  get getConnectedNodeTarget(): ReplaySubject<any>{
    return this.setConnectedNode$;
  }

  openNew(event: any): void {
    this.displayDialog.next(event);
  }

  setData(data: any) {
    this.storeData.next(data);
  }

  setNodeData(data: any) {
    this.nodeData.next(data);
  }

  setCreatedNodeId(input: number, output: number, all: any, connection?: boolean) {
    let params: any = { input: input, output: output, all: all, connection: connection };
    this.setCreatedNode.next(params);
  }

  getCurrentFlow(data: any): any {
    this.currentExecutedRecords = data;
  }

  // Method to set the created node data
  // setCreatedNode(data: any, all: any): void {
  //   const params = { input: data, all: all };
  //   this.createdNodeSubject.next(params);
  // }

  // Optionally, a method to get the current value synchronously
  // getCurrentNode(): any {
  //   return this.createdNodeSubject.value;
  // }

  resetConnectedNode() {
    this.setCreatedNode.next({});
  }
}
