import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService
{
    public _authenticated: boolean = false;
    public currentUser: any;
    public logoutWindow: any;
    private domain: string = environment.domain;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        public location: Location,
        private _router: Router
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('api/auth/sign-in', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                // this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
      // Sign in using the token
      return of(false);
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
      this._httpClient.get(`${this.domain}/api/logout`).subscribe((re: any) => {
        this.logoutWindow = window.open(re.url, 'CCNS Logout', 'width=200, height=100');
        setTimeout(() => { window.location.href = `/`; this.logoutWindow.close(); }, 5000);
        this.afterLogout();
      } , (err: any) => {
        this.logoutWindow = window.open(err.error.url, 'CCNS Logout', 'width=200, height=100');
        setTimeout(() => { window.location.href = `/`; this.logoutWindow.close(); }, 5000);
        this.afterLogout();
      });
        // Remove the access token from the local storage
        // localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    checkSession() {
      this._router.navigate(['/dashboards/project']);
      this._authenticated = true;
      // this._httpClient.get(`${this.domain}/api/company/`).subscribe({
      //   next: (data) => {
      //     console.log(data)
      //     // if (this.tmpData === 'Already logged in' || result) {
      //     //   const shouldCheckPath = (!this.tmpData.isNewSetup);
      //     //   this.afterLogin(this.tmpData, shou+ldCheckPath);
      //     // } else {
      //     //   this.afterLogout();
      //     // }
      //     this._router.navigate(['/dashboards/project']);
      //     this._authenticated = true;
      //   },
      //   error: (error: any) => {
      //     if (error.ok === false) {
      //       if (error.status === 401) {
      //         if (!error.error.url) {
      //           // this.toast.sToast('error', 'Redirect URL not found. Contact Admin.');
      //         } else {
      //           const currentRoute = this._router.url;
      //           if (currentRoute === '/sign-in') {
      //             // this._router.navigate([error.error.url]);
      //             window.location.href = error.error.url;
      //           }
      //         }
      //         // this._authenticated = true;
      //         // this.isAuthenticated = false;
      //         // this.currentUser = undefined;
      //       } else {
      //         // this.afterLogout();
      //         console.log('Check Session error came..');
      //       }
      //     } else {
      //       // this.afterLogout();
      //       console.log('Check Session error came..');
      //     }
      //   }
      // })
      
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
      // this._authenticated = true;
      // Check if the user is logged in
      if ( this._authenticated )
      {
        return of(true);
      }

      // Check the access token availability
      // if ( !this.accessToken )
      // {
      //   return of(false);
      // }

      // Check the access token expire date
      // if ( AuthUtils.isTokenExpired(this.accessToken) )
      // {
      //     return of(false);
      // }

      // If the access token exists and it didn't expire, sign in using it
      return this.signInUsingToken();
    }

    afterLogout(): void {
      this._authenticated = false;
      this.currentUser = undefined;
      localStorage.clear();
      if (this._router.url.indexOf('resetpassword') > -1) {
        console.log('showing reset password. no redirect');
      } else {
        setTimeout(() => {
          this._router.navigateByUrl('/sign-in').then(() => console.log('redirected to login'));
        }, 500);
      }
    }
}
