import { Component, OnInit, ViewChild } from '@angular/core';
import { Sidebar } from 'primeng/sidebar';
import { LayoutService } from 'src/@primeng/services/layout.service';
import { Navigation } from 'src/app/core/config/navigation.config';


@Component({
  selector: 'vertical-navigation',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})
export class VerticalNavigationComponent implements OnInit {
  model: any[] = [];

  constructor(public layoutService: LayoutService) { }

  ngOnInit() {
    this.model = Navigation;
  }
}
