import { CardModule } from 'primeng/card';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReuseTableComponent } from './table.component';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule } from '@angular/forms';
import { ToolbarModule } from 'primeng/toolbar';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { ProgressBarModule } from 'primeng/progressbar';
import { TagModule } from 'primeng/tag';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { ListboxModule } from 'primeng/listbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PipeModule } from '../../pipes/pipe.module';



@NgModule({
  imports: [
    CommonModule,
    TableModule,
    InputTextModule,
    MultiSelectModule,
    ButtonModule,
    TooltipModule,
    FormsModule,
    ToolbarModule,
    FileUploadModule,
    ToastModule,
    ProgressBarModule,
    CardModule,
    TagModule,
    MenuModule,
    MenubarModule,
    ListboxModule,
    OverlayPanelModule,
    PaginatorModule,
    DropdownModule,
    ConfirmDialogModule,
    PipeModule
  ],
  declarations: [ReuseTableComponent],
  exports: [ReuseTableComponent],
  providers: [PrimeIcons, ConfirmationService, MessageService]
})
export class ReusableTableModule { }
