import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassyLayoutComponent } from './classy.component';
import { VerticalNavigationModule } from 'src/app/core/shared/layout/vertical/vertical.module';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MenuModule } from 'primeng/menu';
import { BadgeModule } from 'primeng/badge';
import { AppConfigModule } from 'src/app/core/shared/layout/config/config.module';
import { DynamicBreadcrumbModule } from 'src/app/core/shared/dynamic-breadcrumb/dynamic-breadcrumb/dynamic-breadcrumb.module';



@NgModule({
  imports: [
    CommonModule,
    VerticalNavigationModule,
    RouterModule,
    ButtonModule,
    OverlayPanelModule,
    MenuModule,
    BadgeModule,
    AppConfigModule,
    DynamicBreadcrumbModule
  ],
  declarations: [ClassyLayoutComponent],
  exports: [ClassyLayoutComponent]
})
export class ClassyLayoutModule { }
