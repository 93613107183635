import { AppConfig } from './../core/config/app.config';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Layout } from './layout.types';
import { AuthService } from '../core/auth/auth.service';


@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit {

  layout!: Layout;
  config!: AppConfig

  constructor(private _authService: AuthService) {  }

  ngOnInit() {

    console.log('boolean', )
    if(this._authService._authenticated) {
      // 1. Set the layout from the config
      this.layout = 'classy'; // this.config?.layout ?  this.config.layout : <Layout>'classic' ;
    } else {
      this.layout = 'empty';
    }
  }

}
