import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompactLayoutComponent } from './compact.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [CompactLayoutComponent],
  exports: [CompactLayoutComponent]
})
export class CompactLayoutModule { }
