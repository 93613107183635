import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService, SortEvent } from 'primeng/api';
import { SharedService } from '../../services/shared.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Column, PageEvent, TableOption } from './table.config';
// import { Product } from '../../domain/product';
// import { ProductService } from '../../service/productservice';


@Component({
  selector: 'reuse-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ReuseTableComponent implements OnInit, OnChanges {

  products!: any[];

  rows: number = 10;

  first: number = 0;

  @Input() tableData: any;
  @Input() columns: any;
  @Input() tableOption!: TableOption;
  @Input() filters: any;
  @Input() sortField!: string;
  @Input() sortOrder!: string;
  @Input() globalFilter!: string;
  @Input() resizableColumns: boolean = false;
  @Input() reorderableColumns: boolean = false;
  @Input() columnFilter: boolean = false;
  @Input() paginator: boolean = false;
  @Input() frozenColumns!: number;
  @Input() frozenRows!: number;
  @Input() rowSelection: boolean = false;
  @Input() exportEnabled: boolean = false;
  @Input() scrollable: boolean = false;
  @Input() globalFilterFields: any = ['name', 'country.name', 'representative.name', 'status'];

  loading: boolean = false;

  /*
    *** EVENT EMITTERS
  */
  @Output() openDialog = new EventEmitter<string>();

  @Output() sortCallback = new EventEmitter();

  @Output() hyperlinkCallback = new EventEmitter();

  @Output() refreshCallback = new EventEmitter();

  @Output() menuCallback = new EventEmitter();


  // Other params
  selectedColumns!: Column[];
  cols!: Column[];
  selectedItems!: any;
  selectedProducts!: any | null;
  
  isProgressBarVisible: boolean = true;

  userForm!: FormGroup;

  selectAll: boolean = false;

  downloadItems: any = [
    {
        label: 'Download as',
        items: [
            {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                iconClass: 'text-green-500 text-2xl mr-4',
                command: (event: any) => {
                  this.downloadFile(event.item.label);
                }
            },
            {
                label: 'CSV',
                icon: 'pi pi-file',
                iconClass: 'text-green-500 text-2xl mr-4',
                command: (event: any) => {
                  this.downloadFile(event.item.label);
                }
            },
            {
              label: 'Doc',
              icon: 'pi pi-file-word',
              iconClass: 'text-blue-500 text-2xl mr-4',
              command: (event: any) => {
                this.downloadFile(event.item.label);
              }
            },
            {
              label: 'PDF',
              icon: 'pi pi-file-pdf mr-4',
              iconClass: 'text-red-500 text-2xl mr-4',
              command: (event: any) => {
                this.downloadFile(event.item.label);
              }
            }
        ]
    },]

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public sharedService: SharedService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
    ) {
    }


  ngOnInit(): void {

    /* 
      INITIALIZE COLUMNS
    */
    this.allColumnsVisible() ? this.selectAll = true : this.selectAll = false;
    this.selectedColumns = this.columns;
  }

  ngOnChanges(): void {
    if (this.tableData && this.tableData.length > 0) {
      this.isProgressBarVisible = false;
      this.cdr.detectChanges(); // Manually trigger change detection
    }
  }

  ngAfterViewinit(): void {
    this.cdr.detectChanges(); // Manually trigger change detection for the first time to make sure all data are loaded before rendering
  }

  /* Check if at least one column is visible */
  hasVisible(): boolean {
    if(this.selectedColumns.length > 0) { 
      this.columns.forEach((column: any) => {
        if (!this.selectedColumns.includes(column)) {
          column.isVisible = false;
        }
      });
    }
    this.selectedColumns = this.columns;
    return this.columns.some((key: any) => key.isVisible === true);
  }
  
  

  onGlobalFilterChange(event: any) {
    this.globalFilter = event.target.value;
  }

  lazyLoadSort(event: any) {
    this.loading = true;
    if (this.tableOption.isServerSide && event.sortField !== '') {
      this.sortCallback.emit(event);
      this.loading = false;
    } else if(!this.tableOption.isServerSide && event.sortField !== '') {
      this.loading = false;
      this.tableData.sort((data1: any, data2: any) => {
        let value1 = data1[event.sortField];
        let value2 = data2[event.sortField];
        let result: any = null;

        if (value1 == null && value2 != null) result = -1;
        else if (value1 != null && value2 == null) result = 1;
        else if (value1 == null && value2 == null) result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
        else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

        return event.sortOrder * result;
      });
    }
  }

  searchInput(event: any) {
    console.log(event.target.value);
  }


  setTableOption(): void {
    console.log('set Table Option');
  }

  refreshTable(): void {
    // Implement the logic to refresh the table here
    this.isProgressBarVisible = true;
    setTimeout(() => {
      this.isProgressBarVisible = false;
    }, 1000);

    this.refreshCallback.emit();
    console.log('Table refreshed');
  }

  getColumnsLength(): number {
    return this.columns ? this.columns.length + 2 : 1;
  }
  
  pageChange(event: PageEvent | any) {
    console.log(event);
    this.first = event.first;
    this.tableOption.pageSize = event.rows;
  }


  /**
 * Toggle the column using the isVisible key from given column object
 *
 * @param {event} isVisible - The column key.
 * @returns {boolean} - If all select chekc the checkbox else uncheck.
 * 
 */

  onSelectAllChange(event: any) {
    if(event.checked) {
      this.selectedColumns = this.columns.filter((key: any) => key.isVisible = true);
    } else {
      this.selectedColumns = this.columns.filter((key: any) => key.isVisible = false);
    }
    this.selectAll = event.checked;
    // this.allColumnsVisible() ? this.selectAll = true : this.selectAll = false;
    this.cdr.detectChanges();
  }

  onChange(event: any) {
    // console.log("selected items", this.selectedColumns);
    // console.log("event", event);
    // this.selectedColumns = event && event.value.filter((key: any) => key.isVisible == true);
    this.allColumnsVisible() ? this.selectAll = true : this.selectAll = false;
  }

  /* Check if all columns have isVisible set to true */
  allColumnsVisible(): boolean {
    if(this.selectedColumns && this.selectedColumns.length && this.selectedColumns.length > 0) { 
      this.columns.forEach((column: any) => {
        if (!this.selectedColumns.includes(column)) {
          column.isVisible = false;
        } else {
          column.isVisible = true;
        }
      });
    }
    this.selectedColumns = this.columns;
    return this.columns.every((column: any) => column.isVisible);
  }

  // onExport() {
  //   this.exportService.exportCSV({
  //     data: this.data,
  //     columns: this.columns,
  //     filename: 'table-export'
  //   });
  // }

  addEventHandler(btnType?: string) {
    this.openDialog.emit(btnType);
  }

  // deleteSelectedProducts(event: Event) {
  //   this.confirmationService.confirm({
  //     target: event.target as EventTarget,
  //     message: 'Are you sure you want to delete the selected products?',
  //     header: 'Confirm',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {
  //       this.products = this.products.filter((val) => !this.selectedProducts?.includes(val));
  //       this.selectedProducts = null;
  //       this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
  //     }
  //   });
  // }

  downloadFile(type: string) {
    console.log("event type", type);
  }

  onClickHyperLink(row: any, col: any) {
    let params: any = { row: row, col: col};
    this.hyperlinkCallback.emit(params);
  }

  menuEventCallback(action: any, row: any) {
    let params: any = { action: action, row: row };
    this.menuCallback.emit(params);
  }
}
