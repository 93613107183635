import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form.component';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicControlComponent } from '../dynamic-control/dynamic-control.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DynamicControlModule } from '../dynamic-control/dynamic-control.module';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';


@NgModule({
  imports: [
    CommonModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    ButtonModule,
    DynamicControlModule,
    MessagesModule
  ],
  declarations: [DynamicFormComponent],
  exports: [
    DynamicFormComponent
  ],
  providers: []
})
export class DynamicFormModule { }
