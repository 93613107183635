import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticalNavigationComponent } from './vertical.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SidebarModule } from 'primeng/sidebar';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { AppMenuitemComponent } from './menu-item.component';



@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    SidebarModule,
    AvatarModule,
    ButtonModule,
    MenubarModule,
    MenuModule
  ],
  declarations: [VerticalNavigationComponent, AppMenuitemComponent],
  exports: [VerticalNavigationComponent, AppMenuitemComponent]
})
export class VerticalNavigationModule { }
