import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CustomerIdInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get customer ID from sessionStorage
    const customerId = sessionStorage.getItem('customerId');
    
    if (customerId) {
      // Clone the request and add customer ID to the headers
      const authReq = req.clone({
        headers: req.headers.set('Customer-ID', customerId)
      });
      // Pass the cloned request instead of the original request to the next handler
      return next.handle(authReq);
    }
    else{
        const authReq = req.clone({
            headers: req.headers.set('Customer-ID', "")
          });
          // Pass the cloned request instead of the original request to the next handler
          return next.handle(authReq);  
    }
  }
}
