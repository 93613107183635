import { Route, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { AuthGuard } from './core/auth/guards/auth.guard';

export const appRoutes: Route[] = [


  // Redirect empty path to '/dashboards/project'
  {path: '', pathMatch : 'full', redirectTo: '/database/list'},

  {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: '/database/list'},


  // Auth routes for guests
  {
    path: '',
    canMatch: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      // {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
      // {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
      // {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
      { path: 'sign-in', loadChildren: () => import('./modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
      // {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
    ]
  },

  // Auth routes for authenticated users
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    data: {
        layout: 'empty'
    },
    children: [
        {path: 'sign-out', loadChildren: () => import('./modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
        // {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
    ]
  },

  // Admin routes
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    // resolve: {
    //     initialData: InitialDataResolver,
    // },
    children: [

      // Dashboards
      {
        path: 'dashboards', children: [
          { path: 'project', loadChildren: () => import('./modules/admin/dashboard/project/project.module').then(m => m.ProjectModule) },
          { path: 'project/application', loadChildren: () => import('./modules/admin/dashboard/application/application.module').then(m => m.ApplicationModule) },

        ]
      },

      //Assets
      {
        path:'assets',children:[
          {path:'assetView',loadChildren:() => import('./modules/admin/assets/assetView/asset-view.module').then(m => m.AssetViewModule)},
          {path:'volumes',loadChildren:() => import('./modules/admin/assets/volumes/volumes.module').then(m => m.VolumesModule)},
          {path:'networks',loadChildren:() =>import('./modules/admin/assets/networks/networks.module').then(m => m.NetworksModule) }
        ]

      },

      // Database
      {
        path: 'products', children: [
          { path: 'templates', loadChildren: () => import('./modules/admin/products/templates/templates.module').then(m => m.TemplatesModule) },
        ]
      },

      // Configuration
      {
        path: 'configurations', children: [
          { path: 'data-builder', loadChildren: () => import('./modules/admin/flow-builder/draw-flow/draw-flow.module').then(m => m.DrawFlowModule) },
          { path: 'mdm', loadChildren: () => import('./modules/admin/flow-builder/mdm/mdm.module').then(m => m.MdmModule) },
          { path: 'connection-vault', loadChildren: () => import('./modules/admin/database/database-connect/database-connect.module').then(m => m.DatabaseConnectModule) },
          { path: 'validation', loadChildren: () => import('./modules/admin/settings/validation-configuration/validation-config.module').then(m => m.ValidationConfigModule) },
          { path: 'rule-setup', loadChildren: () => import('./modules/admin/settings/rule-setup/rule-setup.module').then(m => m.RuleSetupModule) },
        ]
      },

      // SETTINGS

      {
        path: 'settings', children: [
          { path: 'notification', loadChildren: () => import('./modules/admin/settings/notification/notification.module').then(m => m.NotificationModule) },
          { path: 'roles', loadChildren: () => import('./modules/admin/settings/roles/roles.module').then(m => m.RolesModule) },
          { path: 'users', loadChildren: () => import('./modules/admin/settings/users/users.module').then(m => m.UsersModule) },
          { path:'cloud-profile',loadChildren:() => import('./modules/admin/settings/cloud-profile/cloud-profile.module').then(m => m.CloudProfileModule) },
          { path: 'jobs', loadChildren: () => import('./modules/admin/settings/jobs/jobs.module').then(m => m.JobsModule) }
        ]
      },
    ]
  }
];
