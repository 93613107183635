

<div class="layout-wrapper" [ngClass]="containerClass">
  <!-- Wrapper -->
  <!-- <div class="flex flex-column flex-auto min-w-0"> -->

    <div class="layout-sidebar" (mouseenter)="toggleSidebar()" (mouseleave)="toggleSidebar()">
      <div class="sidebar-header">
        <a class="app-logo" href="#/"> 
          <!-- <img src="/assets/images/logo.svg"  alt="Logo"> -->
          <span class="app-logo-normal">
            <span class="flex align-items-center">
              <img class="w-3rem h-3rem mr-2" src="../../../../../assets/images/svg/datafusion_logo_3.svg" alt="Logo Inverse"> 
              <h4 class="font-bold mb-0 mt-0">Datafusion</h4>
            </span>
          </span>
          <span class="app-logo-small">
            <img class="w-2rem h-2rem" src="../../../../../assets/images/svg/datafusion_logo_3.svg" alt="Logo Inverse">
          </span>
          <!-- <img src="/assets/images/logo-inverse.svg" alt="Logo Inverse"> -->
        </a>
        <button type="button" class="layout-sidebar-anchor p-link z-2 mb-0" (click)="anchorSidebar()"></button>
      </div>

      <div class="layout-menu-container">
        <vertical-navigation></vertical-navigation>
      </div>
    </div>


    <!-- Content -->

    <div class="layout-content-wrapper">
      <div class="layout-main-container">
        <!-- Header -->
        <div class="layout-topbar">

          <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
            <i class="pi pi-bars"></i>
          </button>

          <dynamic-breadcrumb ></dynamic-breadcrumb>

          <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
            (click)="layoutService.showProfileSidebar()">
            <i class="pi pi-ellipsis-v"></i>
          </button>

          <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
            <button class="p-link layout-topbar-button">
              <i class="pi pi-bell" pBadge value="2"></i>
              <!-- <span>Alert</span> -->
            </button>

            <button class="p-link layout-topbar-button">
              <i class="pi pi-calendar"></i>
              <!-- <span>Calendar</span> -->
            </button>

            <button class="p-link layout-topbar-button" (click)="menu.toggle($event)">
              <i class="pi pi-user"></i>
              <!-- <span>Profile</span> -->
            </button>
            <button class="p-link layout-topbar-button" (click)="onConfigButtonClick()">
              <i class="pi pi-cog"></i>
              <!-- <span>Settings</span> -->
            </button>
          </div>
        </div>
        <p-menu #menu [model]="userItems" [popup]="true"></p-menu>
        
        <div class="layout-main">
          <router-outlet *ngIf="true"></router-outlet>
        </div>
      </div>
    </div>
    <app-config></app-config>
    <!-- <div class="flex flex-col flex-auto p-3" style="margin-left: 300px;"> -->
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
            Otherwise, layout changes won't be registered and the view won't be updated! -->

    <!-- </div> -->
  <!-- </div> -->

</div>
