import { Component, Input, ElementRef } from '@angular/core';
import { LayoutService } from "../../../../../@primeng/services/layout.service";
import { MenuService } from "../../../../../@primeng/services/menu.service";

@Component({
    selector: 'app-config',
    templateUrl: './app.config.component.html'
})
export class AppConfigComponent {

    @Input() minimal: boolean = false;

    scales: number[] = [12, 13, 14, 15, 16];

    themes: any[] = [
        {active: false, theme: 'lara-light-indigo', colorScheme: 'light', colorCode: 'rgb(99, 102, 241)'},
        {active: false, theme: 'lara-light-blue', colorScheme: 'light', colorCode: 'rgb(59, 130, 246)'},
        {active: false, theme: 'lara-light-purple', colorScheme: 'light', colorCode: 'rgb(139, 92, 246)'},
        {active: false, theme: 'lara-light-teal', colorScheme: 'light', colorCode: 'rgb(20, 184, 166)'},
        {active: false, theme: 'lara-light-cyan', colorScheme: 'light', colorCode: 'rgb(34,190,217)'},
        {active: false, theme: 'saga-green', colorScheme: 'light', colorCode: 'rgb(76, 175, 80)'},
        {active: false, theme: 'saga-orange', colorScheme: 'light', colorCode: 'rgb(245, 158, 11)'},
        {active: false, theme: 'saga-purple', colorScheme: 'light', colorCode: 'rgb(156 39 176)'},
        {active: false, theme: 'arya-blue', colorScheme: 'dark', colorCode: 'rgb(100, 181, 246)'},
        {active: false, theme: 'arya-green', colorScheme: 'dark', colorCode: 'rgb(76, 175, 80)'},
        {active: false, theme: 'arya-orange', colorScheme: 'dark', colorCode: 'rgb(245, 158, 11)'},
        {active: false, theme: 'arya-purple', colorScheme: 'dark', colorCode: 'rgb(156 39 176)'}
    ];
    
    lightThemes: any[] = this.themes.filter(theme => theme.colorScheme === 'light');
    darkThemes: any[] = this.themes.filter(theme => theme.colorScheme === 'dark');

    constructor(public layoutService: LayoutService, public menuService: MenuService, public elRef: ElementRef) { }

    get visible(): boolean {
        return this.layoutService.state.configSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.configSidebarVisible = _val;
    }

    get scale(): number {
        return this.layoutService.config.scale;
    }

    set scale(_val: number) {
        this.layoutService.config.scale = _val;
    }

    get menuMode(): string {
        return this.layoutService.config.menuMode;
    }

    set menuMode(_val: string) {
        this.layoutService.config.menuMode = _val;
        this.isChildrenVisible();
    }

    get inputStyle(): string {
        return this.layoutService.config.inputStyle;
    }

    set inputStyle(_val: string) {
        this.layoutService.config.inputStyle = _val;
    }

    get ripple(): boolean {
        return this.layoutService.config.ripple;
    }

    set ripple(_val: boolean) {
        this.layoutService.config.ripple = _val;
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    changeTheme(data: any, colorScheme: string) {
        this.themes.map(t => data.theme === t.theme ? t.active = true : (t.active = false));
        let theme: string = data.theme;
        const themeLink: any = document.getElementById('theme-css');
        const newHref = themeLink.getAttribute('href')!.replace(this.layoutService.config.theme, theme);
        this.layoutService.config.colorScheme;
        this.replaceThemeLink(newHref, () => {
            this.layoutService.config.theme = theme;
            this.layoutService.config.colorScheme = colorScheme;
            this.layoutService.onConfigUpdate();
        });
    }


    replaceThemeLink(href: string, onComplete: Function) {
        const id = 'theme-css';
        const themeLink = <HTMLLinkElement>document.getElementById('theme-css');
        const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            themeLink.remove();
            cloneLinkElement.setAttribute('id', id);
            onComplete();
        });
    }

    decrementScale() {
        this.scale--;
        this.applyScale();
    }

    incrementScale() {
        this.scale++;
        this.applyScale();
    }

    applyScale() {
        document.documentElement.style.fontSize = this.scale + 'px';
    }

    isChildrenVisible() {
        let elements = document.getElementsByClassName('layout-root-menuitem');
        for (let i = 0; i < elements.length; i++) {
            let ulElements = elements[i].getElementsByTagName('ul');
            for (let j = 0; j < ulElements.length; j++) {
                if(this.layoutService.config.menuMode === 'slim' || this.layoutService.config.menuMode === 'slim-plus') {
                    ulElements[j].style.display = 'none';
                } else {
                    ulElements[j].style.display = 'block';
                }
            }
        }
    }

    onSaveClick() {
        const appliedSettings = {
            scale: this.scale,
            theme: this.layoutService.config.theme,
            menuMode: this.menuMode,
            inputStyle: this.inputStyle,
            ripple: this.ripple,
            // Add other settings as needed
        };
        console.log('Applied Settings:', appliedSettings);
    }

    onCancelClick(){
        this.visible=false;
    }
}
