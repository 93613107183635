import { SharedService } from './../../../services/shared.service';
import { Message, MessageService } from 'primeng/api';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControlService } from 'src/app/core/services/form-control.service';
import { Subject } from 'rxjs';
import { JsonFormControls } from 'src/app/core/config/json-form.config';


@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [FormControlService]
})
export class DynamicFormComponent implements OnInit, OnChanges {

  @Input() fnBtnName = 'Save';
  @Input() closeBtnName = 'Close';
  @Input() isCloseBtn = true;
  @Input() isSaveBtn = true;
  @Input() listOfFormElements: any = [];
  @Output() saveCallBack = new EventEmitter();
  @Output() cancelCallBack = new EventEmitter();
  // @Input() selectedValue: any;
  @Output() valueUpdate = new EventEmitter();
  form!: FormGroup;
  @Input() Valuesoutput: any = {};
  @Output() outputValue: any = {};
  @Output() fileChange = new EventEmitter();
  @Output() focusEventChange = new EventEmitter();
  protected onDestroySearch = new Subject<void>();
  message: Message[] = [];

  constructor(
    private fb: FormBuilder,
    private formService: FormControlService, 
    public messageService: MessageService,
    public sharedService: SharedService
  ) { 
  }

  ngOnInit() {
    // this.form = this.formService.toFormGroup(this.listOfFormElements);
    this.form = this.formService.createFormGroup(this.listOfFormElements);
    this.patchInitialValues();
  }

  ngOnChanges() {
    this.form = this.formService.createFormGroup(this.listOfFormElements);
    this.patchInitialValues();
    
  }


  patchInitialValues() {
    // Patch the initial values to the form
    if (this.Valuesoutput && Object.keys(this.Valuesoutput).length > 0) {
      this.form.patchValue(this.Valuesoutput);
    }
  }

  Save(form: any): void {
    console.log('Form valid: ', form.valid);
    console.log('Form valid: ', form.value);
    const frmValues = form.value;
    if (frmValues.netaName && !frmValues.netaName.replace(/\s/g, '').length) {
      this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Input cannot contain only whitespaces' });
    } else if(form.valid) {
      this.saveCallBack.emit(frmValues);
      this.message = [
        {severity: 'success', summary: 'success', detail: "Form is valid. Changes have been saved successfully", life: 4000}
      ];
    } else if(!form.valid){
      // this.message = [
      //   {severity: 'error', summary: 'Error', detail: "Form is not valid. Please fill all fields correctly before proceeding."}
      // ];
      this.message = [
        {severity: 'success', summary: 'success', detail: "Form is valid. Changes have been saved successfully", life: 4000}
      ]
      this.saveCallBack.emit(frmValues);
    }
  }

  Cancel(): void {
    this.cancelCallBack.emit();
  }

  fieldValChange($event: any): void {
    this.valueUpdate.emit($event);
  }

  onSubmit(): void {
    // this.form.value["SelectedDropdown"] = this.selectedValue.value;
    // delete  this.form.value.undefined;
    // @ts-ignore
    this.payLoad = JSON.stringify(this.form.value);
  }

  fileEventChange(event: any) {
    this.fileChange.emit(event);
  }

  focusEventHandle(event: any) {
    this.focusEventChange.emit(event);
  }

  ngOnDestroy(): void {
    // this.form.reset();
    this.onDestroySearch.next();
    this.onDestroySearch.complete();
  }

}
