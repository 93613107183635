import {AfterViewInit, ChangeDetectorRef, Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { JsonFormControls } from '../config/json-form.config';

@Injectable({
  providedIn: 'root'
})
export class FormControlService implements AfterViewInit {
  constructor(private cdr: ChangeDetectorRef, private formBuilder: FormBuilder) {
  }

  // toFormGroup(controls: JsonFormControls[]): any {
  //   const group: any = {};
  //   controls.forEach((control: any) => {
  //     console.log(control);

  //     group[control.key] = control.required ? new FormControl(control.value || '', Validators.required)
  //       : new FormControl(control.value || '');
  //   });
  //   return new FormGroup(group);
  // }

  createFormGroup(parameters: any[]): FormGroup {
    const formGroup = this.formBuilder.group({});

    parameters.forEach(param => {
      const control = this.formBuilder.control('', this.mapValidators(param.validators));
      formGroup.addControl(param.key, control);
    });

    return formGroup;
  }

  private mapValidators(validatorConfig: { [key: string]: any }): any[] {
    const validators = [];

    if (validatorConfig && validatorConfig['required']) {
      validators.push(Validators.required);
    }

    // You can add more validators here as needed

    return validators;
  }

  toFormGroup(controls: JsonFormControls[]): any {
    const group: any = {};
    controls.forEach((control: any) => {
      const validatorsToAdd = [];
      for(const [key, value] of Object.entries(control.validators || {})) {
        switch(key) {
          case 'min':
            validatorsToAdd.push(Validators.min(value as number));
            break;
          case 'max':
            validatorsToAdd.push(Validators.max(value as number));
            break;
          case 'required':
            if (value) {
              validatorsToAdd.push(Validators.required);
            }
            break;
          case 'requiredTrue':
            if (value) {
              validatorsToAdd.push(Validators.requiredTrue);
            }
            break;
          case 'email':
            if (value) {
              validatorsToAdd.push(Validators.email);
            }
            break;
          case 'minLength':
            validatorsToAdd.push(Validators.minLength(value as number));
            break;
          case 'maxLength':
            validatorsToAdd.push(Validators.maxLength(value as number));
            break;
          case 'pattern':
            validatorsToAdd.push(Validators.pattern(value as any));
            break;
          case 'nullValidator':
            if (value) {
              validatorsToAdd.push(Validators.nullValidator);
            }
            break;
          default:
            break;
        }
      }
      group[control.key] = new FormControl(control.value || '', validatorsToAdd);
    });
    return new FormGroup(group);
  }

  toFormControl(control: any): any {
    const group: any = {};
    group[control.key] = control.required ? new FormControl(control.value || '', Validators.required)
      : new FormControl(control.value || '');
    return new FormGroup(group);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
