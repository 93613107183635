import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticalNavigationModule } from './shared/layout/vertical/vertical.module';
import { ReusableTableModule } from './shared/table/table.module';
import { PipeModule } from './pipes/pipe.module';
import { DynamicControlModule } from './shared/reusable-form/dynamic-control/dynamic-control.module';
import { DynamicFormModule } from './shared/reusable-form/dynamic-form/dynamic-form.module';
import { DynamicBreadcrumbModule } from './shared/dynamic-breadcrumb/dynamic-breadcrumb/dynamic-breadcrumb.module';
import { DirectiveDirective } from './directives/directive.directive';

@NgModule({
  imports: [
    CommonModule,
    VerticalNavigationModule,
    ReusableTableModule,
    DynamicControlModule,
    DynamicFormModule,
    DynamicBreadcrumbModule
  ],
  declarations: [	
      DirectiveDirective
   ],
  exports: [VerticalNavigationModule, ReusableTableModule, PipeModule, DynamicBreadcrumbModule]
})
export class CoreModule { }
