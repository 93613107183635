import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicBreadcrumbComponent } from './dynamic-breadcrumb.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';

@NgModule({
  imports: [
    CommonModule,
    BreadcrumbModule
  ],
  declarations: [DynamicBreadcrumbComponent],
  exports: [DynamicBreadcrumbComponent]
})
export class DynamicBreadcrumbModule { }
