import { filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { FormControlService } from 'src/app/core/services/form-control.service';
import { SharedService } from 'src/app/core/services/shared.service';

@Component({
  selector: 'app-dynamic-control',
  templateUrl: './dynamic-control.component.html',
  styleUrls: ['./dynamic-control.component.scss']
})
export class DynamicControlComponent implements AfterViewInit {

  constructor(private formControlService: FormControlService,
    private cdr: ChangeDetectorRef,
    public sharedService: SharedService,
    private messageService: MessageService, private http: HttpClient) {
  }
  @Output() valChange = new EventEmitter();
  @Input() outputValue!: any;
  @Input() formElement!: any;
  @Input() form!: FormGroup;
  @Output() fileUpload = new EventEmitter();
  @Input() isLoading: boolean = false;
  isEnable: boolean = false;

  formData: any = [];

  uploadedFiles: any[] = [];
  uploadUrl = 'https://your-upload-api-url'; // Specify your file upload API URL
  acceptedFormats = '.csv,.xlsx,.xls'; // Specify accepted file formats here
  maxFileSize = 1000000; // Specify maximum file size in bytes
  fileToUpload!: File;
  @Output() focusHandle = new EventEmitter();

  get isValid(): any {
    // @ts-ignore
    return this.form.controls[this.formElement.key].valid;
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {
    // If form is not defined, create a new form group
    if (this.form === undefined) {
      this.form = this.formControlService.createFormGroup(this.formElement);
    }
    this.cdr.detectChanges();
  }

  ngOnChanges(): void {
    this.sharedService.getNodeData$.subscribe((res: any) => { this.formData = res });
  }

  onKeydownMain(e: any): void {
    let i = 0;
    if (e.target.value.length < 1) {
      if (e.keyCode === 32) {
        return;
      }
    } else {
      if (e.keyCode === 32) {
        if (i !== 0) {
          return;
        }
        i++;
      } else {
        i = 0;
      }
    }
  }

  selectedFile(file: any, key: any): void {
    this.outputValue[key] = file;
  }

  callBack($event: any, callback: boolean, controlName: any): void {
    // this.outputValue[controlName] = $event.value;
    // this.form.controls[controlName].setValue($event.value);
    callback = true;
    if (callback) {
      const ctrl = { event: $event, controlName: controlName };
      this.valChange.emit(ctrl);
    }
  }

  // hasError(controlName: string, errorName: string): boolean {
  //   // console.log(this.form.get(controlName)?.hasError(errorName));
  //   return this.form.get(controlName)?.hasError(errorName) || false;
  // }
  hasError(controlName: string, errorName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.form.get(controlName)?.hasError(errorName) || false);
      }, 0);
    });
  }

  enableDisableControl(key: string): boolean {
    console.log(this.formElement);
    let isEnable = false; // Initialize isEnable variable
  
    // Check if the control exists and has dependencies
    this.formData.node[this.formData.nodeId].data['form'].parameters.map((obj: any) => {
      if (obj && obj.depends_on && obj.depends_on.length > 0) {
        // Check if the key is included in the control's dependencies
        isEnable = obj.depends_on.includes(key);
      }
    });
    console.log(isEnable); // Log the result for debugging
  
    return isEnable; // Return the result
  }

  isDependenciesMet(field: any): boolean {
    // Check if all dependencies are present in some way (e.g., selected value for select fields)
    return field.depends_on.every((dependency: any) => {
      // Check if the dependency value exists (you may need to adapt this based on your actual data structure)
      return this.form.get(dependency)?.value !== undefined;
    });
  }

  isDependenciesEqual(ctrlName: any, field: any): boolean {
    if(field.name === 'Topics') {
      field.options.map((obj: any) => {
        return this.form.get(ctrlName)?.value === ""
        ? false
        : obj === this.form.get(ctrlName)?.value
      })
    }
    return false;
  }
  

  getOptionLabel(formElement: any): string {
    // Check if options exist and have at least one item
    if (formElement.options && formElement.options.length > 0) {
      // Check if options are objects
      if (typeof formElement.options[0] === 'object') {
        // Check if options have a 'display_name' property
        if (formElement.options[0].hasOwnProperty('display_name')) {
          return 'display_name';
        } if (formElement.options[0].hasOwnProperty('displayLabel')) { // Check if options have a 'displayLabel' property
          return 'displayLabel';
        } else if (formElement.options[0].hasOwnProperty('name')) { 
          return 'name';
        } else if (formElement.options[0].hasOwnProperty('file_name')) { 
          return 'file_name';
        } else {
          // If neither 'displayLabel' nor 'name' is present, return an empty string
          return '';
        }
      } else {
        return '';
      }
    }
    // Default to 'displayLabel' if options are not provided, empty, or not objects
    return 'displayLabel';
  }

  getOptionValue(formElement: any): string {
    // Check if options exist and have at least one item
    if (formElement.options && formElement.options.length > 0) {
      // Assuming options are objects with a 'value' property
      if(formElement.options[0].hasOwnProperty('value')) {
        return 'value';
      } else if (formElement.options[0].hasOwnProperty('id')) {
        return 'id';
      } else {
        return '';
      }
    }
    // Default to 'value' if options are not provided or empty
    return 'value';
  }

  onFileUploadSuccess(event: any) {
    console.log(event);
    this.fileToUpload = event.files[0];
    this.fileUpload.emit(event.files[0])
  }


  uploadFile() {
    if (this.fileToUpload) {
      this.messageService.add({ severity: 'info', summary: 'Uploading file', detail: 'Started to upload' });
      const formData = new FormData();
      formData.append('file', this.fileToUpload);

      this.http.post(this.uploadUrl, formData)
        .subscribe(
          response => {
            this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
            // Handle success response
          },
          error => {
            this.messageService.add({ severity: 'error', summary: 'File Upload Failed', detail: error.message });
            // Handle error response
          }
        );
    } else {
      this.messageService.add({ severity: 'warn', summary: 'No file selected', detail: 'Please select a file to upload.' });
    }
  }

  eventFocusHandle(event: any): void {
    console.log(event);
    this.focusHandle.emit(event);
  }
}
