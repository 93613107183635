import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import 'codemirror/lib/codemirror';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/hint/show-hint';
// import 'codemirror/addon/hint/sql-hint';
import { environment } from './environments/environment';

if(environment.production) {
  window.console.log = () => {}
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
