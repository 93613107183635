import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'app'
})
export class AppPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return null;
  }

}

@Pipe({
  name: 'filter',
  // pure: false // necessary to update when the array changes
})
export  class FilterPipe implements PipeTransform {  
  transform(items: any[], field: string, value: string) {
      if (!items || !field || !value) { return items; }
      return items.filter((item) => {
          let itemValue = item[field];
          /*
            Handle special case of "isnull" filter which returns items where a specified property is null or not present.
            Support for arrays in data fields (e.g., tags: ["tag1", "tag2"])  
          */
         if (Array.isArray(itemValue)) { 
          itemValue = itemValue.join(" "); 
        }
        return itemValue && itemValue.toLowerCase().indexOf(value.toLowerCase()) > -1;
      });
  }
}

@Pipe({name: 'formatCell'})
export class FormatCellPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  transform(value: any, format: string): any {
    if (value === undefined) {
      return '';
    }
    if (format === 'date') {
      return this.datePipe.transform(value, 'medium');
    } else if (format === 'cameltohuman') {
      return new CamelToHumanPipe().transform(value, true);
    } else if (format === 'bytesconvert') {
      return new BytesConvertFilterPipe().transform(value, 0);
    } else if (format === 'epochToDate') {
      return new EpochToDateFilterPipe().transform(value);
    } else if (format === 'utcToLocale') {
      return new UtcToLocale().transform(value);
    } else if (format === 'cveToDate') {
      return new CveToDateFilterPipe().transform(value);
    } else if (format === 'dateAgo') {
      return new DateAgoPipe().transform(value);
    } else if (format === 'formatTrafficUnits') {
      return new FormatTrafficUnitsPipe().transform(value);
    } else if (format === 'macFilter') {
      return new MacFilterPipe().transform(value);
    } else if (format === 'arrayToStr') {
      return new ArrayToStrPipe().transform(value);
    } else if (format === 'arrayToStrWithEllipsis') {
      return new ArrayToStrEllipsisPipe().transform(value);
    } else if (format === 'daysHoursSeconds') {
      return new DaysHoursSecondsPipe().transform(value);
    } else if (format === 'timeAgo') {
      return new TimeAgoPipe().transform(value);
    } else if (format.indexOf('ellipsis') > -1) {
      const prms = value.split(':');
      return new EllipsisPipe().transform(prms[0], prms[1]);
    } else if (format.indexOf('fileNameFilter') > -1) {
      return new FileNameFilterPipe().transform(value);
    } else if (format.indexOf('maskpassword') > -1) {
      return new MaskPasswordFilterPipe().transform(value);
    }
    return value;
  }
}

@Pipe({
  name: 'camelToHuman'
})
export class CamelToHumanPipe implements PipeTransform {
  transform(input: any, uppercaseFirst: any): any {
    if (typeof input !== 'string') {
      return input;
    }
    let result = input.replace(/([a-z\d])([A-Z])/g, '$1' + (' ' || '_') + '$2');
    if (result.indexOf('_') > -1) {
      result = result.replace(/(?:_| |\b)(\w)/g,
        (key, p1) => p1.toUpperCase()).replace(/([a-z])([A-Z])/g, '$1 $2');
    }
    if (uppercaseFirst) {
      result = result.charAt(0).toUpperCase() + result.slice(1);
    }
    return result;
  }
}


@Pipe({
  name: 'bytesConvert'
})
export class BytesConvertFilterPipe implements PipeTransform {
  transform(bytes: any, decimals: number = 2): any {
    if (bytes === 0 || !bytes || bytes === '' || bytes === null) {
      return '0';
    } else if (typeof bytes === 'string') {
      bytes = Number(bytes);
    }
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let unit = 0;
    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }
    return bytes.toFixed(+decimals) + ' ' + units[unit];
  }
}


@Pipe({
  name: 'epochToDate'
})
export class EpochToDateFilterPipe implements PipeTransform {
  transform(epoch: any): any {
    if (!epoch) {
      return '-';
    }
    epoch = (epoch + '').split('.')[0];
    const d = (epoch.toString().length === 10) ? new Date(epoch * 1000) : new Date(+epoch);
    return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
  }
}

@Pipe({name: 'utcToLocale'})
export class UtcToLocale implements PipeTransform {
  transform(date: string): string {
    if (date === null || !date || date === '') { return date; }
    if (date && date.indexOf('Z') === -1) { date += 'Z'; }
    return date ? new Date(date).toLocaleDateString() + ' ' + new Date(date).toLocaleTimeString() : date;
  }
}


@Pipe({
  name: 'cveToDate'
})
export class CveToDateFilterPipe implements PipeTransform {
  transform(epoch: any): any {
    if (!epoch) {
      return '-';
    }
    epoch = epoch + '';
    const year = epoch.slice(0, 4);
    const month = epoch.slice(4, 6);
    const date = epoch.slice(6, 8);
    return year + '/' + month + '/' + date;
  }
}


@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value || value === '') {
      return '-';
    }
    let content = '';
    const year = Math.floor(value / ((365 * 24) * 3600));
    value = value % ((365 * 24) * 3600);
    if (year > 0) {
      content += year + 'y ';
    }
    const day = Math.floor(value / (24 * 3600));
    value = value % (24 * 3600);
    if (day > 0) {
      content += day + 'd ';
    }
    const hour = Math.floor(value / 3600);
    value %= 3600;
    if (hour > 0) {
      content += hour + 'h ';
    }
    const minutes = Math.floor(value / 60);
    value %= 60;
    if (minutes > 0 && !args) {
      content += minutes + 'm ';
    }
    const seconds = Math.floor(value);
    if (seconds > 0 && !args) {
      content += seconds + 's ';
    }
    return content;
  }
}


@Pipe({
  name: 'formatTrafficUnits'
})
export class FormatTrafficUnitsPipe implements PipeTransform {
  transform(units: any, decimals?: any, display?: any, base?: any): any {
    if (!units) {
      return '';
    }
    if (display === undefined) {
      display = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps'];
    }
    if (units === 0) {
      return units + display[0];
    }
    base = base || 1000; // or 1024 for binary
    const dm = decimals || 2;
    const i = Math.floor(Math.log(units) / Math.log(base));
    return parseFloat((units / Math.pow(base, i)).toFixed(dm)) + display[i];
  }
}


@Pipe({
  name: 'macFilter'
})
export class MacFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value || value === '') {
      return '';
    }
    return value.match(/.{1,2}/g).join(':');
  }
}


@Pipe({
  name: 'arrayToStr'
})
export class ArrayToStrPipe implements PipeTransform {
  transform(input: any, args?: any): any {
    if (!input || input === null || input === '') {
      return '';
    }
    if (input.length > 0) {
      return input.join(', ');
    } else {
      return '';
    }
  }
}


@Pipe({
  name: 'arrayToStrWithEllipsis'
})
export class ArrayToStrEllipsisPipe implements PipeTransform {
  transform(input: any, args?: any): any {
    if (!(input && input !== '')) {
      return '';
    }
    if (input.length < 3) {
      return input.join(', ');
    } else if (input.length > 2) {
      return input[0] + ', ' + input[1] + ', ...';
    } else {
      return '';
    }
  }
}


@Pipe({
  name: 'daysHoursSeconds'
})
export class DaysHoursSecondsPipe implements PipeTransform {
  transform(sec: any, args?: any): any {
    if (!sec) {
      return sec;
    } else {
      let t = Math.floor(sec / 100);
      // tslint:disable-next-line:prefer-const
      let years;
      // tslint:disable-next-line:prefer-const
      let months;
      let days;
      if (t > 86400) {
        days = Math.floor(t / 86400);
        t = t - (days * 86400);
      }
      const hours = Math.floor(t / 3600);
      t = t - (hours * 3600);
      const minutes = Math.floor(t / 60);
      t = t - (minutes * 60);
      let content = '';
      if (years) {
        content += years + ' years';
      }
      if (months) {
        if (content) {
          content += ', ';
        }
        content += months + ' months';
      }
      if (days) {
        if (content) {
          content += ', ';
        }
        content += days + ' days';
      }
      if (hours || days) {
        if (content) {
          content += ', ';
        }
        content += hours + ' hours';
      }
      if (content) {
        content += ', ';
      }
      content += minutes + ' minutes and ' + t + ' seconds.';
      return content;
    }
  }
}


@Pipe({name: 'timeAgo'})
export class TimeAgoPipe implements PipeTransform {
  transform(value: any): string {
    if (!value) { return value; }
    const d = (value.length === 10) ? new Date(value * 1000) : new Date(value);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 45) {
      return 'A few seconds ago';
    } else if (seconds <= 90) {
      return 'A minute ago';
    } else if (minutes <= 45) {
      return minutes + ' minutes ago';
    } else if (minutes <= 90) {
      return 'An hour ago';
    } else if (hours <= 22) {
      return hours + ' hours ago';
    } else if (hours <= 36) {
      return 'A day ago';
    } else if (days <= 25) {
      return days + ' days ago';
    } else if (days <= 45) {
      return 'A month ago';
    } else if (days <= 345) {
      return months + ' months ago';
    } else if (days <= 545) {
      return 'A year ago';
    } else { // (days > 545)
      return years + ' years ago';
    }
  }

  private getSecondsUntilUpdate(seconds: number): number {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) { // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) { // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) { // less then a day, update every 5 mins
      return 300;
    } else { // update every hour
      return 3600;
    }
  }
}


// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe {
  transform(val: any, args?: any): any {
    if (!val) { return val; }
    args = (!args) ? 25 : args;
    if (typeof val === 'object' && val.length && val.length !== undefined) {
      val = val.join(', ');
    } else if (typeof val === 'object' && val.length === undefined) {
      val = JSON.stringify(val).replace('{', '')
        .replace('}', '')
        .replace(/\\/g, '');
    } else {
      val = val + '';
    }
    if (val.length > args) {
      return val.substring(0, args) + '...';
    } else {
      return val;
    }
  }
}

@Pipe({
  name: 'fileNameFilter',
  pure: true
})
export class FileNameFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) { return value; }
    return value.split('\\').pop().split('/').pop().split('.')[0];
  }
}

@Pipe({
  name: 'maskPassword'
})
export class MaskPasswordFilterPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return '-';
    }
    return '***********';
  }
}